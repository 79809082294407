import {setDefaultCurrency} from "./setDefaultCurrency";
import {formatCategoryFilterPrices} from "./formatCategoryFilterPrices";

function updateProductPrices(prices, currency)
{
    if (currency == null) {
        currency = 'gbp';
    }

    var currency_rate_div = $('.currency_rate'),
        rate = Number(prices[currency]);

    if (currency === 'gbp') {
        formatCategoryFilterPrices(currency, rate);
        currency_rate_div.hide();
    } else {
        formatCategoryFilterPrices(currency, rate);
        currency_rate_div.
        html('Chards Live Exchange Rate: 1 '+ currency.toUpperCase() + ' = ' + (1 / rate).toFixed(4) + ' GBP').
        attr('data-currency_rate', ( 1 / rate)).
        show();
    }

    /*var add_increment = false;
    if ((currency == 'usd' || currency == 'jpy') && ($('.basket-wrapper').length || $('.checkout-basket').length || $('.checkout-summary').length)) {
        add_increment = true;
    }*/

    $( ".product-price" ).each(function() {
        if ($(this).attr('data-exchange-rate')) {
            rate = $(this).attr('data-exchange-rate');
        }

        var gbp_price = $(this).attr('data-price');

        if (typeof $(this).attr('data-quantity-price') !== 'undefined' ) {
            gbp_price = $(this).attr('data-quantity-price');
        }

        gbp_price = Number(gbp_price);

        var converted_price = gbp_price * rate;

        /*if (add_increment) {
            if ($(this).parent().hasClass('checkout-basket-total') || $(this).parent().hasClass('overall')) {
                converted_price = converted_price + 0.07;
            }
        }*/

        var rounded_converted_price = converted_price.toFixed(2);
        //symbol = getCurrencySymbol(currency);

        if (isNaN(converted_price)) {
            rounded_converted_price = gbp_price.toFixed(2);
        }

        var formatter = new Intl.NumberFormat('en-GB', {
            style: 'currency',
            currency: currency,
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
            signDisplay: 'auto'
        });

        $(this).text(formatter.format(rounded_converted_price));

        //$(this).text(symbol + numberWithCommas(rounded_converted_price));
        $(this).addClass('loaded');
    });

    // Lbma revert
    //var basket_total = $('#page-basket .basket-items .summary .totals .overall .product-price');

    //if (basket_total.length > 0) {
    //var container = $('#page-basket'),
    //lbma_popup_shown = container.hasClass('lbma-popup-shown'),
    //basket_total_numeric = basket_total.attr('data-price'),
    //stock_required_products = $('.stock-required');

    //if (!lbma_popup_shown && basket_total_numeric >= 50000 && stock_required_products.length > 0) {
    //var dt = new Date(),
    //time = dt.getHours() + ":" + dt.getMinutes() + ":" + dt.getSeconds(),
    //within_time_period = withinTimePeriod(time);

    //if (!within_time_period) {
    //container.addClass('lbma-popup-shown');
    //showPopup('#popup-lbma-fix');
    //}
    //}
    //}

    return setDefaultCurrency(currency);

}

export {updateProductPrices}